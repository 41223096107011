$yellow: #fddb51;

// $body-color: $ghost-white;
// $body-bg: $gunmetal;
$primary: $yellow;

@import '~bootstrap/scss/bootstrap';

html,
body,
#result {
	height: 100%;
}


#result {
	display: flex;
	flex-direction: column;
}

.nav,
main {
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

ul.bullet {
	list-style: none;
}

ul.bullet li {
	padding-left: 2.5rem;
	background: url(../images/bullet.svg) 0 0.3rem no-repeat;
	background-size: 1.5rem;
}

.cover {
	object-fit: cover;
	object-position: center;
}